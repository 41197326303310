import { useAsync } from "react-async";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as authClient from "../utils/auth_client";
import { createContext, useContext, useEffect } from "react";
import { FullPageSpinner } from "../components/lib";

const AuthContext = createContext();

// async function bootstrapAppData() {
//   const user = await authClient.getUser();

//   if (!user) {
//     return { user: null };
//   }
//   return {
//     user,
//   };
// }

function AuthProvider(props) {
  // const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);

  const {
    data = { user: { loading: false } },
    error,
    isRejected,
    isPending,
    // isSettled,
    reload,
  } = useAsync({
    promiseFn: authClient.getUser,
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        reload(authClient.getUser);
      }
    });
    return unsubscribe;
  }, [reload]);

  // useLayoutEffect(() => {
  //   if (isSettled) {
  //     setFirstAttemptFinished(true);
  //   }
  // }, [isSettled]);

  // if (!firstAttemptFinished) {
  if (isPending) {
    return <FullPageSpinner />;
  }
  if (isRejected) {
    return (
      <div className="text-red-500 flex justify-center items-center h-full">
        <p>
          Rayos... ha ocurrido al inesperado. Trata de refrescar la página 🙏.
        </p>
        <pre>{error.message}</pre>
      </div>
    );
  }
  // }

  const login = (form) => authClient.login(form).then(reload);
  const register = (form) => authClient.register(form).then(reload);
  const logout = () => authClient.logout().then(reload);
  const updateProfile = (user, data) =>
    authClient
      .updateProfile(user, data)
      .then(localStorage.setItem("__admissions__current", "code"))
      .then(reload);

  const updateApplicationReload = (id, data) =>
    authClient.updateUser(id, data).then(reload);

  return (
    <AuthContext.Provider
      value={{
        data,
        login,
        logout,
        register,
        updateProfile,
        updateApplicationReload,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
