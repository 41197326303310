import { useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function Spinner(props) {
  return <FaSpinner className="animate-spin" aria-label="loading" {...props} />;
}

export function FullPageSpinner() {
  return (
    <div className="text-2xl flex justify-center items-center h-full text-gray-600">
      <Spinner />
    </div>
  );
}

export function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}
