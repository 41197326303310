import { leadCohort } from "../config";
const Airtable = require("airtable");
const currentCohort = "cohort-14";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

const base = Airtable.base("appXU2SngtJDBiAkq");

function formatRecord(record) {
  return {
    idAT: record.id,
    ...record.fields,
  };
}

function formatRecords(records) {
  return records.map(formatRecord);
}

export function getCandidate(email) {
  return new Promise((resolve, reject) => {
    base("Candidates")
      .select({
        view: "Grid view",
        filterByFormula: `{email} = "${email}"`,
      })
      .firstPage(function (err, records) {
        if (err) {
          reject(err);
        }
        resolve(formatRecords(records)[0]);
      });
  });
}

export function getApplications(email) {
  return new Promise((resolve, reject) => {
    base("Applications")
      .select({
        view: "Grid view",
        filterByFormula: `{email} = "${email}"`,
      })
      .firstPage(function (err, records) {
        if (err) {
          reject(err);
        }
        resolve(formatRecords(records));
      });
  });
}

export async function getUser(email) {
  const [candidate, applications] = await Promise.all([
    getCandidate(email),
    getApplications(email),
  ]);
  candidate.application =
    applications.find((a) => a.cohort === currentCohort) || null;
  if (!candidate.application) {
    const application = await createApplication(candidate.idAT);
    candidate.application = application;
  }
  return candidate;
}

export function getLead(email) {
  return new Promise((resolve, reject) => {
    base("Leads")
      .select({
        view: "Grid view (NO TOCAR)",
        filterByFormula: `{Email} = "${email}"`,
      })
      .firstPage(function (err, records) {
        if (err) {
          reject(err);
        }
        resolve(formatRecords(records)[0]);
      });
  });
}

export function updateLead(id, data) {
  return new Promise((resolve, reject) => {
    base("Leads").update(
      id,
      {
        Apellidos: data.lastName,
        Celular: data.phone,
        País: data.countryCode,
        Nombre: data.firstName,
      },
      function (err, record) {
        if (err) {
          reject(err);
        }
        resolve(formatRecord(record));
      }
    );
  });
}

export function createLead({
  firstName,
  lastName,
  countryCode,
  phone,
  email,
  utm,
}) {
  return new Promise((resolve, reject) => {
    base("Leads").create(
      {
        Apellidos: lastName,
        Email: email,
        Celular: phone,
        País: countryCode,
        Nombre: firstName,
        Cohort: leadCohort,
        UTM_Source: utm.utm_source,
        UTM_Medium: utm.utm_medium,
        UTM_Campaign: utm.utm_campaign,
        UTM_Term: utm.utm_term,
        UTM_Content: utm.utm_content,
      },
      function (err, record) {
        if (err) {
          reject(err);
        }
        resolve(formatRecord(record));
      }
    );
  });
}

export function createCandidate(
  { firstName, lastName, countryCode, phone, email },
  leadId
) {
  return new Promise((resolve, reject) => {
    base("Candidates").create(
      {
        firstName,
        lastName,
        countryCode,
        phone,
        email,
        lead: [leadId],
      },
      function (err, record) {
        if (err) {
          reject(err);
        }
        resolve(formatRecord(record));
      }
    );
  });
}

export function createApplication(candidateId) {
  return new Promise((resolve, reject) => {
    base("Applications").create(
      {
        cohort: currentCohort,
        candidate: [candidateId],
        register: true,
      },
      function (err, record) {
        if (err) {
          reject(err);
        }
        resolve(formatRecord(record));
      }
    );
  });
}

export async function createUser(data) {
  let lead;
  try {
    lead = await getLead(data.email);
  } catch (error) {
    console.log(error.message);
  }
  if (!lead) lead = await createLead(data);

  const candidate = await createCandidate(data, lead.idAT);
  const application = await createApplication(candidate.idAT);
  candidate.application = application;
  return candidate;
}

export function updateCandidate(candidateId, data) {
  return new Promise((resolve, reject) => {
    base("Candidates").update(candidateId, data, function (err, record) {
      if (err) {
        reject(err);
      }
      resolve(formatRecord(record));
    });
  });
}

export function updateApplication(applicationId, data) {
  return new Promise((resolve, reject) => {
    base("Applications").update(applicationId, data, function (err, record) {
      if (err) {
        reject(err);
      }
      resolve(formatRecord(record));
    });
  });
}

export async function submitProfile(candidateId, applicationId, data) {
  const [candidate, application] = await Promise.all([
    updateCandidate(candidateId, data),
    updateApplication(applicationId, { profile: true }),
  ]);
  candidate.application = application;
  return candidate;
}

export function getWebMessages() {
  return new Promise((resolve, reject) => {
    base("WebMessages")
      .select({
        view: "Grid view",
      })
      .firstPage(function (err, records) {
        if (err) {
          reject(err);
        }
        resolve(formatRecords(records));
      });
  });
}

export function getQuestions(attempt) {
  return new Promise((resolve, reject) => {
    base("CodingQuestions")
      .select({
        view: "Grid view",
        filterByFormula: `(AND({cohort} = "${currentCohort}", {attempt} = "${attempt}"))`,
      })
      .firstPage(function (err, records) {
        if (err) {
          reject(err);
        }
        resolve(formatRecords(records));
      });
  });
}

export function submitQuestion(user, question, answer, correct) {
  return new Promise((resolve, reject) => {
    base("CodingResult").create(
      {
        application: [user.application.idAT],
        question: [question.idAT],
        candidateAnswer: answer,
        correct: correct,
      },
      function (err, record) {
        if (err) {
          reject(err);
        }
        resolve(formatRecord(record));
      }
    );
  });
}
