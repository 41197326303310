// import client from "./api-client";
import { initializeApp } from "firebase/app";
import * as airtable from "./airtable";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
const localStorageKey = "__admissions__";

const firebaseConfig = {
  apiKey: "AIzaSyB1_ZxZMOS3PZnJ0jl0gEu4shrqZkW69lg",
  authDomain: "codeable-admissions.firebaseapp.com",
  projectId: "codeable-admissions",
  storageBucket: "codeable-admissions.appspot.com",
  messagingSenderId: "85065710824",
  appId: "1:85065710824:web:3afb974906a3b344a79d5d",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);

function handleUserResponse({ accessToken, ...user }) {
  window.localStorage.setItem(localStorageKey, accessToken);
  return user;
}

// OK
function getUser() {
  const token = getToken();
  const user = auth.currentUser;
  if (!token) {
    return Promise.resolve({ user: null });
  }
  if (token && !user) {
    return Promise.resolve({ user: { loading: true } });
  }

  return airtable
    .getUser(user.email)
    .then((data) => {
      const u = { user: data };
      u.user.update = function (data) {
        for (let key in data) {
          this[key] = data[key];
        }
      };
      return u;
    })
    .catch((error) => {
      logout();
      return Promise.reject(error);
    });

  // return client("me").catch((error) => {
  //   logout();
  //   return Promise.reject(error);
  // });
}

function login({ email, password }) {
  // return client("login", { body: { username, password } }).then(
  //   handleUserResponse
  // );
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      return handleUserResponse(user);
    })
    .catch(() => {
      throw new Error("Credenciales inválidas 😵");
    });
}

async function register(form) {
  // return client("register", { body: { username, password } }).then(
  //   handleUserResponse
  // );
  const { email, password, ...rest } = form;

  // try {
  //   await airtable.createUser(user);
  //   const userCredential = await createUserWithEmailAndPassword(
  //     auth,
  //     email,
  //     password
  //   );
  //   const user = { ...userCredential.user, ...rest };
  //   return handleUserResponse(user);
  // } catch (error) {
  //   console.error(error.message);
  // }

  return createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const user = { ...userCredential.user, ...rest };

      return airtable.createUser(user).then((user) => {
        return handleUserResponse(user);
      });
    }
  );
  // .catch((error) => {
  //   throw new Error(error);
  // });

  // return Promise.resolve(
  //   handleUserResponse({ user: { token: "testToken", ...user } })
  // );
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
  localStorage.removeItem("__admissions__current");
  // return Promise.resolve();
  return signOut(auth).catch((error) => {
    console.error(error.message);
  });
}

function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

async function updateProfile(user, formData) {
  await airtable.updateLead(user.lead[0], formData);
  return airtable.submitProfile(user.idAT, user.application.idAT, formData);
}

function updateUser(id, data) {
  return airtable.updateApplication(id, data).then(() => {
    localStorage.setItem("__admissions__current", "code");
  });
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export {
  login,
  register,
  logout,
  getUser,
  updateProfile,
  updateUser,
  auth,
  resetPassword,
};
